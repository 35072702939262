<template>
    <div class="flex aic" style="height: 100%;">
        <div class="menu-icon">
            <i :class="!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="TargetCollapse"></i>
        </div>

        <el-breadcrumb separator="/">
            <template v-for="(item, index) in route.matched" :key="index">
                <el-breadcrumb-item v-if="index > 0">{{item.meta.title}}</el-breadcrumb-item>
            </template>
        </el-breadcrumb>

        <div class="header-right">
            <el-dropdown>
                <div class="user-info" v-if="userInfo?.name">
                    <!-- <img src="https://img0.baidu.com/it/u=3311900507,1448170316&fm=26&fmt=auto&gp=0.jpg" alt="" class="user-logo"> -->
                    <span>欢迎，{{userInfo?.name || 'XXX'}}</span>
                </div>
                <div class="user-info" v-else>
                    <span>欢迎，XXX</span>
                </div>

                <template #dropdown>        
                    <el-dropdown-menu>
                    <!-- <el-dropdown-item>资料编辑</el-dropdown-item>
                    <el-dropdown-item>修改密码</el-dropdown-item> -->
                    <el-dropdown-item @click="Logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
    <!-- <p v-for="(item, index) in menus" :key="index">{{item.meta.title}}</p> -->

</template>

<script>
    import { ref, computed, inject } from 'vue'
    import { useStore } from 'vuex';
    import { useRoute, useRouter }from 'vue-router'
    import cookies from 'vue-cookies'
    import { AdminLogout } from '@/api/index'

    export default {
        setup () {
            let count = ref(9)
            let search = ref('');
            let store = useStore();
            let menus = computed(() => store.getters.GET_MENUS)
            let userInfo = computed(() => store.getters.GET_USERINFO)
            let isCollapse = inject('isCollapse')
            const route = useRoute()
            const elplus = inject('elplus')

            // 切换菜单显示
            const TargetCollapse = () => {
                isCollapse.value = !isCollapse.value;
            }
            // 退出登录
            const userRouter = useRouter()
            // console.log(route.meta)
            const Logout  = () => {
                let loading = elplus.loading({text: '退出登录中...'})
                AdminLogout().then(() => {
                    loading.close()
                    cookies.remove("ADMIN_TOKEN");
                    store.commit('SET_MENUS', [])
                    userRouter.push('/login')
                })
            }
            return {
                search,
                menus,
                count,
                isCollapse,
                route,
                TargetCollapse,
                userInfo,
                Logout
            }
        }
    }
</script>


<style lang="scss" scoped>

.menu-icon{
    font-size: 20px;
    color: #333333;
    cursor: pointer;
    margin-right: 20px;
}
.header-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    .el-input {
        width: 250px;
        margin-right: 30px;
        /deep/ .el-input__inner{
            border-radius: 8px;
            height: 32px;
        }
    }
    .item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;
        .icon{
            margin-right: 8px;
            font-size: 20px;
        }
    }
    .user-info {
        display: flex;
        align-items: center;
        .user-logo {
            display: block;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            margin-right: 10px;
        }
    }
}
</style>

